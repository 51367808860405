import React from 'react';

import classNames from 'classnames/bind';
import Accordions from '@COMPONENTS/COMMON/accordion/accordion';
import styles from '../DocumentSidebar/DocumentSidebar.module.scss';
import SectionSideBarTreeView from './SectionSidebarTreeview.component';

const cx: CX = classNames.bind(styles);

function ChapterSideBarTreeView(props: any) {
    const {
        list, chapterId, addSubSectionButton,
    } = props;

    return (
        <ul>
            {list?.map((item: any) => {
                const content = item?.children?.length ? (
                    <SectionSideBarTreeView
                        list={item.children}
                        addSubSectionButton={addSubSectionButton}
                        chapterId={chapterId}
                        parentId={item.id}
                    />
                ) : (
                    addSubSectionButton('Add Sub-Section', chapterId, item.id)
                );
                return (
                    <Accordions
                        key={item.id}
                        title={item.name}
                        detailsContain={content}
                    />
                );
            })}
            {addSubSectionButton('Add Section', chapterId)}
        </ul>
    );
}

export default ChapterSideBarTreeView;
