import React from 'react';
import classNames from 'classnames/bind';
import styles from './ChapterSideBarTreeView.module.scss';

const cx: CX = classNames.bind(styles);

function SectionSubMenu(props: any) {
    const {
        list, addSubSectionButton, chapterId, parentId,
    } = props;

    return (
        <ul>
            {list?.map((item: any) => (
                <li key={item.id}>
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={() => {}}
                        className={cx('charpter-link')}
                    >
                        {item.name}
                    </div>
                </li>
            ))}
            {addSubSectionButton('Add Sub Sub-Section', chapterId, parentId)}
        </ul>
    );
}

export default SectionSubMenu;
