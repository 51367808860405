import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import classnames from 'classnames/bind';

import { Typography } from '@mui/material';
import styles from './accordion.module.scss';

const cx: CX = classnames.bind(styles);

function Accordions(props: AccordionsProps) {
    const {
        title, detailsContain, defaultExpanded = false, classNames, handleClick, headerSection, icon,
    } = props;

    const Icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
            <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
        </svg>
    );
    const handleButtonClick = () => {
        if (handleClick) {
            handleClick();
        }
    };

    return (
        <div
            role="button"
            tabIndex={0}
            className={cx('accordion-wrapper')}
            onClick={handleButtonClick}
        >
            <Accordion defaultExpanded={defaultExpanded}>
                <AccordionSummary
                    className={cx('accordion-summary', classNames)}
                    expandIcon={Icon}
                    aria-controls="panel1-content"
                    id="panel1-header"
                >
                    <Typography className={cx('accordion-title-wrapper')}>
                        <Typography className={cx('accordion-title')}>
                            {title}
                        </Typography>
                        {icon}
                    </Typography>

                    {headerSection}
                </AccordionSummary>
                <AccordionDetails className={cx('accordion-details', 'custom-accordion-details')}>
                    {detailsContain}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

type AccordionsProps = {
    title: string;
    detailsContain: React.ReactNode;
    defaultExpanded?: boolean;
    classNames?: string;
    handleClick?: () => void;
    headerSection?: React.ReactNode;
    icon?: React.ReactNode;
};

Accordions.defaultProps = {
    defaultExpanded: false,
    classNames: '',
    handleClick: () => {},
    headerSection: null,
    icon: null,
};

export default Accordions;
