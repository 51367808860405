import React from 'react';
import Accordions from '@COMPONENTS/COMMON/accordion/accordion';
import SectionSubMenu from './SectionSubMenu.component';

function SectionSideBarTreeView(props: any) {
    const {
        list, addSubSectionButton, chapterId, parentId,
    } = props;

    return (
        <ul>
            {list?.map((item: any) => {
                const content = item.children.length ? (
                    <SectionSubMenu
                        list={item.children}
                        addSubSectionButton={addSubSectionButton}
                        chapterId={chapterId}
                        parentId={item.id}
                    />
                ) : (
                    addSubSectionButton('Add Sub Sub-Section', chapterId, item.id)
                );
                return (
                    <Accordions
                        key={item.id}
                        title={item.name}
                        detailsContain={content}
                    />
                );
            })}
            {addSubSectionButton('Add Sub-Section', chapterId, parentId)}
        </ul>
    );
}

export default SectionSideBarTreeView;
