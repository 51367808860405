/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface NewSectionModalState {
    isOpen: boolean;

    data: {
        documentId: number;
        parent: number | null;
        chapterId: number;
    } | null;
}

export interface NewSectionModalDispatchParams {
    documentId: number;

    chapterId: number;
    parent: number | null;
}

const initialState: NewSectionModalState = {
    isOpen: false,
    data: null,
};

export const newSectionModalSlice = createSlice({
    name: 'new-section-modal',
    initialState,
    reducers: {
        open(state: NewSectionModalState, action: PayloadAction<NewSectionModalDispatchParams>) {
            state.isOpen = true;
            state.data = action.payload;
        },
        close(state: NewSectionModalState) {
            state.isOpen = false;
            state.data = null;
        },
    },
});
