import React, {
    MutableRefObject,
    RefObject,
    useCallback,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import PropTypes, { InferProps } from 'prop-types';

import classnames from 'classnames/bind';
import { Editor } from '@tinymce/tinymce-react';

import COLOR from '@CONSTANTS/COLOR.constant';
import PDF from '@CONSTANTS/PDF.constant';

import getEditorConfig from '@HELPERS/getEditorConfig';

import CircularProgressIndicator from '@COMPONENTS/SHARED/CircularProgressIndicator';

import styles from './BaseEditor.module.scss';

const cx: CX = classnames.bind(styles);

const EDITOR_MARGIN = PDF['pdf-editor-margin'];
const PAGE_HEIGHT = PDF['pdf-page-height'];
const PAGE_WIDTH = PDF['pdf-page-width'];

function BaseEditor(props: Props) {
    const {
        initialValue, isLandscape, onEditorChange, variantKey,
    } = props;

    const baseEditorRef: RefObject<HTMLDivElement> = useRef(null);
    const landscapeLabelRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
    const boundsRef: MutableRefObject<HTMLDivElement | null> = useRef(null);

    const editorRef: MutableRefObject<any> = useRef(null);
    const timer: MutableRefObject<any> = useRef(null);

    const [isEditorReady, setEditorReady] = useState<boolean>(false);

    const editorConfig = getEditorConfig(
        isLandscape ? 'landscape' : 'portrait',
    );

    function toggleBounds() {
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            const { contentAreaContainer } = editorRef.current;

            const { height } = contentAreaContainer.getBoundingClientRect();

            if (height > PAGE_WIDTH + EDITOR_MARGIN) {
                boundsRef.current!.style.visibility = 'visible';
            } else {
                boundsRef.current!.style.visibility = 'hidden';
            }
        }, 150);
    }

    function handleEditorChange(content: string) {
        onEditorChange(content);

        if (isLandscape) {
            toggleBounds();
        }
    }

    const setupEditorElements = useCallback(() => {
        if (landscapeLabelRef.current || boundsRef.current) return;

        const toxSidebarWrap = baseEditorRef.current?.querySelector(
            '.tox-sidebar-wrap',
        ) as HTMLDivElement;
        if (!toxSidebarWrap) return;

        const landscapeLabel = document.createElement('div');
        landscapeLabel.classList.add(cx('landscape'));
        landscapeLabel.innerText = 'Landscape';

        const bounds = document.createElement('div');
        bounds.classList.add(cx('bounds'));

        toxSidebarWrap.appendChild(landscapeLabel);
        toxSidebarWrap.appendChild(bounds);

        landscapeLabelRef.current = landscapeLabel;
        boundsRef.current = bounds;
    }, []);

    const getLayoutWidth = useCallback(() => {
        setupEditorElements();

        if (!editorRef.current) return;

        const { contentAreaContainer, contentWindow } = editorRef.current;
        if (!contentAreaContainer || !contentWindow) return;

        const rootClassList = contentWindow.document.documentElement.classList;
        rootClassList.toggle('landscape', isLandscape);
        rootClassList.toggle('portrait', !isLandscape);

        if (isLandscape) {
            landscapeLabelRef.current!.style.visibility = 'visible';
            toggleBounds();
        } else {
            landscapeLabelRef.current!.style.visibility = 'hidden';
            boundsRef.current!.style.visibility = 'hidden';
        }
    }, [isLandscape, setupEditorElements]);

    useLayoutEffect(() => {
        const width = isLandscape ? PAGE_HEIGHT : PAGE_WIDTH;
        document.documentElement.style.setProperty(
            '--editor-width',
            `${width}px`,
        );
    }, [isLandscape]);

    const cleanFontSizes = (content: string) => content.replace(/font-size:\s*(\d+)pt;/g, (match, size) => {
        const pxSize = `${parseInt(size, 10)}px`; // Convert pt to px
        return `font-size: ${pxSize};`;
    });

    const cleanedContent = cleanFontSizes(initialValue);

    return (
        <>
            <div
                ref={baseEditorRef}
                className={cx('base-editor', {
                    show: isEditorReady,
                })}
            >
                <Editor
                    key={variantKey}
                    onInit={(evt, editor) => {
                        editorRef.current = editor;
                        setEditorReady(true);
                        getLayoutWidth();
                        handleEditorChange(editor.getContent());
                    }}
                    onEditorChange={(content) => {
                        handleEditorChange(content || '');
                    }}
                    initialValue={cleanedContent}
                    init={{
                        ...editorConfig,

                        height: 700,
                        min_height: 700,
                        paste_as_text: false,
                        paste_data_images: true,
                        paste_word_valid_elements: 'b,strong,i,em,h1,h2,h3',
                        paste_retain_style_properties: 'all',
                        toolbar: [
                            'undo redo | styleselect | bold italic underline strikethrough | fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist',
                            'forecolor backcolor permanentpen formatpainter removeformat  | charmap emoticons | preview print | image media template link anchor | ltr rtl',
                        ],
                        toolbar_sticky: true,
                        toolbar_sticky_offset: 80,
                        browser_spellcheck: false,
                        spellchecker: false,
                    }}
                />
            </div>
            {!isEditorReady && (
                <div className={cx('editor-loading')}>
                    <CircularProgressIndicator
                        color={COLOR['marriott-primary']}
                        size={40}
                        thickness={4}
                    />
                </div>
            )}
        </>
    );
}

BaseEditor.defaultProps = {
    variantKey: '',
};

BaseEditor.propTypes = {
    initialValue: PropTypes.string.isRequired,
    variantKey: PropTypes.string,
    isLandscape: PropTypes.bool.isRequired,
    onEditorChange: PropTypes.func.isRequired,
};

type Props = InferProps<typeof BaseEditor.propTypes>;

export default BaseEditor;
