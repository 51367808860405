import React from 'react';
import AddButton from '@COMPONENTS/COMMON/buttons/AddButton';
import classNames from 'classnames/bind';
import useNewSectionModal from '@HOOKS/store/modals/useNewSectionModal';
import styles from './AddSectionButton.module.scss';

const cx: CX = classNames.bind(styles);

function AddSectionButton(props: any) {
    const {
        locked, title, payload,
    } = props;
    const { actions: newSectionModalActions } = useNewSectionModal(() => true);

    return (
        <div className={cx('subsection-button-wrapper')}>
            <AddButton
                locked={locked}
                icon
                title={title}
                fontSize={36}
                onClick={() => {
                    newSectionModalActions.open({
                        documentId: payload.documentId,
                        chapterId: payload.chapterId,
                        parent: payload.parentId,
                    });
                }}
            />
        </div>
    );
}

export default AddSectionButton;
